body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ControlPanelLaterale:hover {
  opacity: 1;
}

.ControlPanelLateraleTitle {
  color: white;
  font-size: 2rem;
/*border: 1px solid black; */
  height: 100%;
}

.ControlPanelLateraleDrawTitle {
  color: white;
  font-size: 1.4rem;
/*border: 1px solid black; */
  margin: 0;
}

.polylineOptionsContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  /* border: solid 1px white; */
}

.polylineOptionsContainerInfo{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  /* border: solid 1px white; */
}

.polylineOptions{
  font-size: 1rem;
}

.ControlPanelLateraleTitleWindy {
  color: white;
  font-size: 2rem;
/*border: 1px solid black; */
  height: 100%;
}

.navBarSystem {
  z-index: 1000;
  opacity: 0.8;
}

.disabled {
  opacity: 0.3; /* Opacità ridotta per evidenziare che l'elemento è disabilitato */
  cursor: not-allowed; /* Cambia il cursore quando l'elemento è disabilitato */
  pointer-events: none; /* Impedisce gli eventi del mouse sugli elementi disabilitati */
}

.ControlPanelLaterale {
  width: 240px;
  /*height: 275px;*/
}

.ControlPanelLaterale2 {
  width: 180px;
  height: 130px;
  /*height: 275px;*/
}

.ControlPanelLaterale2-open {
/*border: 1px solid black; */
  background: rgba(105, 105, 105, 0.7);
  left: 0px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 50px;
  z-index: 1000;
  position: absolute;
  opacity: 1;
  border-radius: 10px;
  border:1px solid gray;
  padding: 10px;
  -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  width: 240px;
  transition: height 0.6s ease; /* Aggiungi la transizione all'altezza con una durata di 0.3 secondi e una funzione di easing "ease" (puoi personalizzare questi valori) */
  overflow: hidden;
}

.ControlPanelLaterale2-open2 {
  /*border: 1px solid black; */
  background: rgba(105, 105, 105, 0.7);
  right: 0px;
  margin-top: 400px;
  margin-right: 10px;
  margin-bottom: 50px;
  z-index: 1000;
  position: absolute;
  opacity: 1;
  border-radius: 10px;
  border:1px solid gray;
  padding: 10px;
  -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  width: 240px;
  transition: height 0.6s ease; /* Aggiungi la transizione all'altezza con una durata di 0.3 secondi e una funzione di easing "ease" (puoi personalizzare questi valori) */
  overflow: hidden;
}

.ControlPanelLaterale2-open3 {
  /*border: 1px solid black; */
  background: rgba(105, 105, 105, 0.7);
  right: 0px;
  margin-top: 50px;
  margin-right: 10px;
  margin-bottom: 50px;
  z-index: 1000;
  position: absolute;
  opacity: 1;
  border-radius: 10px;
  border:1px solid gray;
  padding: 10px;
  -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  max-width: 220px;
  transition: height 0.6s ease; /* Aggiungi la transizione all'altezza con una durata di 0.3 secondi e una funzione di easing "ease" (puoi personalizzare questi valori) */
  overflow: hidden;
}

.ControlPanelLaterale2-open4 {
  /*border: 1px solid black; */
  background: rgba(105, 105, 105, 0.7);
  right: 0px;
  margin-top: 160px;
  margin-right: 10px;
  margin-bottom: 50px;
  z-index: 1000;
  position: absolute;
  opacity: 1;
  border-radius: 10px;
  border:1px solid gray;
  padding: 10px;
  -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  max-width: 280px;
  transition: height 0.6s ease; /* Aggiungi la transizione all'altezza con una durata di 0.3 secondi e una funzione di easing "ease" (puoi personalizzare questi valori) */
  overflow: hidden;
}

.ControlPanelLaterale2-open5 {
  /*border: 1px solid black; */
  background: rgba(105, 105, 105, 0.7);
  right: 0px;
  margin-top: 300px;
  margin-right: 10px;
  margin-bottom: 50px;
  z-index: 1000;
  position: absolute;
  opacity: 1;
  border-radius: 10px;
  border:1px solid gray;
  padding: 10px;
  -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  max-width: 280px;
  transition: height 0.6s ease; /* Aggiungi la transizione all'altezza con una durata di 0.3 secondi e una funzione di easing "ease" (puoi personalizzare questi valori) */
  overflow: hidden;
}

.ControlPanelLaterale2-open6 {
  /*border: 1px solid black; */
  background: rgba(105, 105, 105, 0.7);
  right: 0px;
  margin-top: 325px;
  margin-right: 10px;
  margin-bottom: 50px;
  z-index: 1000;
  position: absolute;
  opacity: 1;
  border-radius: 10px;
  border:1px solid gray;
  padding: 10px;
  -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  max-width: 280px;
  transition: height 0.6s ease; /* Aggiungi la transizione all'altezza con una durata di 0.3 secondi e una funzione di easing "ease" (puoi personalizzare questi valori) */
  overflow: hidden;
}

.ControlPanelProgetti{
  min-width: 250px;
}

.ControlPanelLaterale2-open7{
  /*border: 1px solid black; */
  background: rgba(105, 105, 105, 0.7);
  right: 0px;
  margin-top: 230px;
  margin-right: 10px;
  margin-bottom: 50px;
  z-index: 1000;
  position: absolute;
  opacity: 1;
  border-radius: 10px;
  border:1px solid gray;
  padding: 10px;
  -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  max-width: 280px;
  transition: height 0.6s ease; /* Aggiungi la transizione all'altezza con una durata di 0.3 secondi e una funzione di easing "ease" (puoi personalizzare questi valori) */
  overflow: hidden;
  min-height: 200px;
  min-width: 250px;
}

.ControlPanelLaterale2-close {
  /*border: 1px solid black; */
  background: rgba(105, 105, 105, 0.7);
  left: 0px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 50px;
  z-index: 1000;
  position: absolute;
  opacity: 1;
  border-radius: 10px;
  border:1px solid gray;
  padding: 10px;
  -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  width: 240px;
  transition: height 0.6s ease; /* Aggiungi la transizione all'altezza con una durata di 0.3 secondi e una funzione di easing "ease" (puoi personalizzare questi valori) */
  overflow: hidden;
  /*height: 275px;*/
}

  .ControlPanelLaterale2-openWindy {
    /*border: 1px solid black; */
      background: rgba(105, 105, 105, 0.7);
      left: 0px;
      margin-top: 10px;
      margin-left: 10px;
      margin-bottom: 50px;
      z-index: 1000;
      position: absolute;
      opacity: 1;
      border-radius: 10px;
      border:1px solid gray;
      padding: 10px;
      -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
      -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
      box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
      width: 240px;
      transition: height 0.6s ease; /* Aggiungi la transizione all'altezza con una durata di 0.3 secondi e una funzione di easing "ease" (puoi personalizzare questi valori) */
      overflow: hidden;
    }
    
    .ControlPanelLaterale2-closeWindy {
      /*border: 1px solid black; */
        background: rgba(105, 105, 105, 0.7);
        left: 0px;
        margin-top: 10px;
        margin-left: 10px;
        margin-bottom: 50px;
        z-index: 1000;
        position: absolute;
        opacity: 1;
        border-radius: 10px;
        border:1px solid gray;
        padding: 10px;
        -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
        -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
        box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
        width: 240px;
        transition: height 0.6s ease; /* Aggiungi la transizione all'altezza con una durata di 0.3 secondi e una funzione di easing "ease" (puoi personalizzare questi valori) */
        overflow: hidden;
        /*height: 275px;*/
      }

  .ControlPanelLateraleResponsive2-open{
    background: rgba(105, 105, 105, 0.7);
    left: 0px;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 50px;
    z-index: 1000;
    position: absolute;
    opacity: 1;
    border-radius: 10px;
    border:1px solid gray;
    padding: 10px;
    -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    width: 150px;
    height: 170px;
    transition: height 0.6s ease; /* Aggiungi la transizione all'altezza con una durata di 0.3 secondi e una funzione di easing "ease" (puoi personalizzare questi valori) */
    overflow: hidden;
  }

  .ControlPanelLateraleResponsive2-close{
    background: rgba(105, 105, 105, 0.7);
    left: 0px;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 50px;
    z-index: 1000;
    position: absolute;
    opacity: 1;
    border-radius: 10px;
    border:1px solid gray;
    padding: 10px;
    -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    width: 150px;
    height: 40px;
    transition: height 0.6s ease; /* Aggiungi la transizione all'altezza con una durata di 0.3 secondi e una funzione di easing "ease" (puoi personalizzare questi valori) */
    overflow: hidden;
  }

  .ControlPanelLaterale2-unauthorized{
    background: rgba(105, 105, 105, 0.7);
    left: 0px;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 50px;
    z-index: 1000;
    position: absolute;
    opacity: 1;
    border-radius: 10px;
    border:1px solid gray;
    padding: 10px;
    -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
    width: 220px;
    height: 100px;
  }

.ControlPanelLaterale2-1 {
  /*border: 1px solid black; */
  width: 100%;
  /*height: 45px;*/
  /*display: flex;
  justify-content: center;
  align-items: center;
  */
}

.ControlPanelLaterale2-2-1 {
  /*border: 1px solid black; */
  /*height: 100%;*/
}

.ControlPanelLaterale2-2-open {
  /*border: 1px solid black; */
}

.ControlPanelLaterale2-2-close {
  /*border: 1px solid black; */
}

.ControlPanelLaterale2-1Windy {
  /*border: 1px solid black; */
  width: 100%;
  /*height: 45px;*/
  /*display: flex;
  justify-content: center;
  align-items: center;
  */
}

.ControlPanelLaterale2-2-1Windy {
  /*border: 1px solid black; */
  /*height: 100%;*/
}

.ControlPanelLaterale2-2-openWindy {
  /*border: 1px solid black; */
}

.ControlPanelLaterale2-2-closeWindy {
  /*border: 1px solid black; */
}

.buttonFlyObjectPanel{
    width: 175px;
}


.ControlPanel {
  top: 0px;
  margin-top: 20px;
  margin-left: 50px;
  z-index: 1000;
  position: absolute;
  background-color: white;
  opacity: 0.5;
  border-radius: 10px;
  border:1px solid gray;
  padding: 10px;
  -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
}

.ControlPanel:hover {
  opacity: 1!important;
}

.selectedZoomLevel {
  background-color: blue;
  color: white;
}

.boxInfoSatellite {
  border:1px solid gray;
}


.imgQwebHeader1{
  width: 30px;
  height: 25px;
}

.imgQwebHeader{
  width: 25px;
  height: 25px;
}

@media (max-width: 361px) {
  .imgQwebHeader1{
    width: 20px;
    height: 15px;
  }

  .imgQwebHeader{
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 341px) {
  .imgQwebHeader1{
    width: 15px;
    height: 10px;
  }

  .imgQwebHeader{
    width: 10px;
    height: 10px;
  }
}

#hormenu{
  height: 100px;
  width: 300px;
}

#hormenu2{
  height: 230px;
  width: 300px;
}

.scrollBarClicked {
  overflow-y: auto;
  /*height: 200px;*/
  width: 220px;
}

.scrollBar {
 /* height: 450px;*/
 overflow-y: auto;
  width: 220px;
}

.scrollBarClickedWindy {
  overflow-y: auto;
  /*height: 200px;*/
  width: 220px;
}

.scrollBarWindy {
 /* height: 450px;*/
 overflow-y: auto;
  width: 220px;
}

.scrollBar::-webkit-scrollbar {
  width: 12px;
  }

.scrollBar::-webkit-scrollbar-track {
    background-color: #ccc;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.scrollBar::-webkit-scrollbar-thumb {
    background-color: rgba(105, 105, 105, 0.7);
    border-radius: 10px;
}

.scrollBarClicked::-webkit-scrollbar {
  width: 12px;
  }

.scrollBarClicked::-webkit-scrollbar-track {
    background-color: #ccc;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.scrollBarClicked::-webkit-scrollbar-thumb {
    background-color: rgba(105, 105, 105, 0.7);
    border-radius: 10px;
}

.scrollBarWindy::-webkit-scrollbar {
  width: 12px;
  }

.scrollBarWindy::-webkit-scrollbar-track {
    background-color: #ccc;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.scrollBarWindy::-webkit-scrollbar-thumb {
    background-color: rgba(105, 105, 105, 0.7);
    border-radius: 10px;
}

.scrollBarClickedWindy::-webkit-scrollbar {
  width: 12px;
  }

.scrollBarClickedWindy::-webkit-scrollbar-track {
    background-color: #ccc;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.scrollBarClickedWindy::-webkit-scrollbar-thumb {
    background-color: rgba(105, 105, 105, 0.7);
    border-radius: 10px;
}

#controlPanel2{
  background: rgba(105, 105, 105, 0.7);
  left: 0px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 50px;
  z-index: 1000;
  position: absolute;
  opacity: 1;
  border-radius: 10px;
  border:1px solid gray;
  padding: 10px;
  -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  width: 220px;
}

#controlPanel3{
  background: rgba(105, 105, 105, 0.7);
  left: 0px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 50px;
  z-index: 1000;
  position: absolute;
  opacity: 1;
  border-radius: 10px;
  border:1px solid gray;
  padding: 10px;
  -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  width: 200px;
}

#controlPanel4{
  background: rgba(105, 105, 105, 0.7);
  left: 0px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 50px;
  z-index: 1000;
  position: absolute;
  opacity: 1;
  border-radius: 10px;
  border:1px solid gray;
  padding: 10px;
  -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  width: 200px;
}

.buttonText {
  border-radius: 10px;
  background-color: white;
  border: none;
  color: black;
  text-align: center;
  font-size: 16px;
  padding-top: 3px;
  width: 110px;
  transition: all 0.5s;
  cursor: pointer;
}

.buttonText2 {
  border-radius: 10px;
  background-color: white;
  border: none;
  color: black;
  text-align: center;
  font-size: 16px;
  padding-top: 3px;
  transition: all 0.5s;
  cursor: pointer;
  margin-bottom: 5px;
}

.buttonText span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.buttonText span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.buttonText:hover span {
  padding-right: 25px;
}

.buttonText:hover span:after {
  opacity: 1;
  right: 0;
}


.latlonText{
  color: rgb(255, 255, 255);
  font-size: 18px;
  margin-bottom: -0.1px;
}

.inputText{
  width: 175px;
  height: 27px;
  font-size: 18px;
  border-radius: 5px;
  margin-bottom: 7px;
}

#buttonPun{
  margin-top: -2px;
}

#buttonPer{
  margin-top: -2px;
}

#buttonRic{
  margin-top: -2px;
}

.containerButt{
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

/* vale come per infobox, vedi ragionamento CSS */

.scrollBarClickedResponsive {
  display: none;
  overflow: auto;
  height: 250px;
  width: 200px;
}

.scrollBarResponsive {
  overflow: auto;
  height: 125px;
  width: 130px;
}

.ControlPanelLateraleTitle-responsive {
  color: white;
  font-size: 1rem;
/*border: 1px solid black; */
  height: 100%;
}

/* AGGIUNTA Responsive al Control Panel */

.ControlPanelLateraleResponsive {
  width: 200px;
}

/* inizio altre aggiunte */

.navBarSystemPortrait {
  z-index: 1000;
  opacity: 0.8;

  height: auto;
/*  margin-top: -1.5vh;*/
}

.borderProp{
  border: 1px solid black;
  width: 100%;
}

.borderProperty{
  border: 1px solid black;
}

.borderProperty2{
  border: 2px solid black;
}

.borderProperty3{
  border: 3px solid black;
}

.borderProperty4{
  border: 4px solid black;
}

.grandezzaIcona{
  width: 100px;
  height: 100px;
}

.buttRetryControlPanel{
  height: 30px;
  width: 30px;
  margin-top: -5px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 15px !important;
}

.buttRenameProjectControlPanel{
  height: 33px;
  width: 33px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 15px !important;
  /* font-size: 16px; */
  color: black;
  margin-right: 3px;
}

.buttDownloadProjectControlPanel{
  height: 33px;
  width: 33px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 15px !important;
  /* font-size: 16px; */
  color: black;
  margin-right: 3px;
}

.buttRemoveProjectProjectControlPanel{
  height: 33px;
  width: 33px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 15px !important;
  /* font-size: 16px; */
  color: black;
}

.buttRenameObjectControlPanel{
  height: 25px;
  width: 20px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 12px !important;
  font-size: 13px;
  color: black;
  margin-right: 3px;
}

.buttDownloadObjectControlPanel{
  height: 25px;
  width: 20px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 12px !important;
  font-size: 13px;
  color: black;
  margin-right: 3px;
}

.buttRemoveObjectControlPanel{
  height: 25px;
  width: 20px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 12px !important;
  font-size: 13px;
  color: black;
}

.buttRetryControlPanelWindy{
  height: 30px;
  width: 30px;
  margin-top: -5px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 15px !important;
}

.contenitoreIcona{
  margin-left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*border-radius: 15px;*/
  z-index: 1005;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 25px solid rgb(190, 190, 190);
  cursor: pointer;
  border-top: none;
  opacity: 0;
  transition: opacity 0.6s ease; /* Aggiunge una transizione di opacità */
}

.contenitoreIconaWindy{
  margin-left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*border-radius: 15px;*/
  z-index: 1005;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 25px solid rgb(190, 190, 190);
  cursor: pointer;
  border-top: none;
  opacity: 0;
  transition: opacity 0.6s ease; /* Aggiunge una transizione di opacità */
}

.contenitoreIconaResp{
  margin-left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*border-radius: 15px;*/
  z-index: 1005;
  width: 0;
  height: 0;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-bottom: 17px solid rgb(163, 163, 163);
  border-top: none;
}

.contenitoreIcona.visible{
  opacity: 1;
}

.contenitoreIconaWindy.visible{
  opacity: 1;
}

.contenitoreIcona2Resp.visible{
  opacity: 1;
}

.contenitoreIcona.invisible{
  opacity: 0;
}

.contenitoreIconaWindy.invisible{
  opacity: 0;
}

.contenitoreIcona2Resp.invisible{
  opacity: 0;
}

.contenitoreIcona:hover{
  border-bottom: 25px solid gray;
}

.contenitoreIconaWindy:hover{
  border-bottom: 25px solid gray;
}

.contenitoreIcona:active{
  border-bottom: 25px solid rgb(163, 163, 163);
}

.contenitoreIconaWindy:active{
  border-bottom: 25px solid rgb(163, 163, 163);
}

.contenitoreIcona2{
  margin-left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1005;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid rgb(190, 190, 190);
  cursor: pointer;
  border-bottom: none;
}

.contenitoreIcona2Windy{
  margin-left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1005;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid rgb(190, 190, 190);
  cursor: pointer;
  border-bottom: none;
}

.contenitoreIcona2Resp{
  margin-left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1005;
  width: 0;
  height: 0;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-top: 17px solid rgb(163, 163, 163);
  border-bottom: none;
}

.contenitoreIcona2.visible{
  opacity: 1;
}

.contenitoreIcona2Windy.visible{
  opacity: 1;
}

.contenitoreIcona2.invisible{
  opacity: 0;
}

.contenitoreIcona2Windy.invisible{
  opacity: 0;
}

.contenitoreIcona2:hover{
  border-top: 25px solid gray;
}

.contenitoreIcona2:active{
  border-top: 25px solid rgb(163, 163, 163);
}

.contenitoreIcona2Windy:hover{
  border-top: 25px solid gray;
}

.contenitoreIcona2Windy:active{
  border-top: 25px solid rgb(163, 163, 163);
}

.iconaFreccia {
  margin-top: 30px !important;
  color: black;
}

.iconaFreccia2 {
  margin-bottom: 30px !important;
  color: black;
}

.iconaFrecciaWindy {
  margin-top: 30px !important;
  color: black;
}

.iconaFreccia2Windy {
  margin-bottom: 30px !important;
  color: black;
}

.iconaFrecciaResp {
  margin-top: 20px;
  color: black;
}

.iconaFreccia2Resp {
  margin-bottom: 20px;
  color: black;
}

.divContainerButtSeleziona{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
/*border: 1px solid black; */
}

/* .divContainerButtCheckboxProject{
  width: 60px;
  height: 33px;
  background-color: #f3f3f3;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* CHECKBOX PROGETTO */

.divContainerButtCheckboxProject {
  background-color: #f3f3f3;
  height: 33px;
  width: 33px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 15px !important;
  color: black;
  margin-right: 3px;
  flex-shrink: 0; /* Impedisce il ridimensionamento */
}

.divContainerButtCheckboxProject input[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 2px solid #000;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ✅ Pseudo-elemento per la spunta nera centrata */
.divContainerButtCheckboxProject input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  width: 5px;
  height: 9px;
  border: solid black; /* Spunta nera */
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%) rotate(45deg); /* Centro perfetto */
}

/* FINE CHECKBOX PROGETTO */


/* CHECKBOX OGGETTO */

.divContainerButtCheckboxObject {
  background-color: #f3f3f3;
  height: 25px;
  width: 25px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 12px !important;
  color: black;
  margin-right: 3px;
  flex-shrink: 0; /* Impedisce il ridimensionamento */
}

.divContainerButtCheckboxObject input[type="checkbox"] {
  width: 13px;
  height: 13px;
  margin: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 2px solid #000;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ✅ Pseudo-elemento per la spunta nera centrata */
.divContainerButtCheckboxObject input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  width: 4px;
  height: 7px;
  border: solid black; /* Spunta nera */
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%) rotate(45deg); /* Centro perfetto */
}

/* FINE CHECKBOX OGGETTO */

.divContainerButtSelezionaWindy{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
/*border: 1px solid black; */
}

.divContainerButtSeleziona2{
  display: flex;
  justify-content: center;
  align-items: center;
/*border: 1px solid black; */
}

.barraNavigazione{
  border-bottom: 1px solid black;
  visibility: visible;
  max-height: 200px;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, max-height 0.3s ease-in-out;
}

.barraNavigazioneWindy{
  border-bottom: 1px solid black;
  visibility: visible;
  max-height: 200px;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, max-height 0.3s ease-in-out;
  position: absolute !important;
  top: 0 !important;
  display: none !important;
  z-index: 100000000000000000;
}

.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.navBarSystemLandscape {
  z-index: 1000;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16vh;
  margin-top: -3vh;
}

.altezzaHeaderLogout{
  height: 74px;
}

.scrollBarResponsive::-webkit-scrollbar {
  width: 12px;
  }

.scrollBarResponsive::-webkit-scrollbar-track {
    background-color: #ccc;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.scrollBarResponsive::-webkit-scrollbar-thumb {
    background-color: rgba(105, 105, 105, 0.7);
    border-radius: 10px;
}

/*
#controlPanelNuovoResponsive{
  background: rgba(105, 105, 105, 0.7);
  left: 0px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 50px;
  z-index: 1000;
  position: absolute;
  opacity: 1;
  border-radius: 10px;
  border:1px solid gray;
  padding: 10px;
  -webkit-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  -moz-box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  box-shadow: 10px 10px 44px -32px rgba(0,0,0,0.67);
  width: 150px;
}
*/

#irResponsive{
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
}

#rgbResponsive{
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
}

.spacePhoto{
  margin-top: -13px;
}

.sizePanelPhoto{
  height: 50px;
}

/* AGGIUNTE */

.font-rem{
  font-size: 13px;
}

.divPopup{
  /*border: 1px solid black;*/
  min-height: 80px;
  min-width: 80px;
  margin: -7px;
  margin-right: -4px;
}

.borderImages{
  border: 1px solid black;

}

.divImagesPressed{
  border: 1px solid black;
  width: 350px;
}

.coordinate{
  /*border: 1px solid black;*/
  align-items: center;
  justify-content: center;
}

.font-weight-responsive{
  /*border: 1px solid black;*/
  font-weight: 700;
}

.common-link{
  color: blue;
}

.mini-img-popup {
  width: 100px;
  height: 75px;
  border-radius: 10px;
  border:1px solid gray;
}

.mini-img-popup-pc {
  width: 100px;
  height: 75px;
  border-radius: 10px;
  border:1px solid gray;
  cursor: pointer;
}

.mini-img-popup-cell {
  width: 60px;
  height: 45px;
  border-radius: 10px;
  border:1px solid gray;
}

.openImagePopup-pc {
  /*
  width: 300px;
  height: 300px;
  */
}

.dimensioniImmagini{
  width: 350px;
  height: auto;
}

.dimensioniContenitoreImmagini{
  
}

.openImagePopup-cell {
  width: 148px;
  height: 111px;
}

/*
.openImagePopup-pc-container{
  height: 600px;
  width: 600px;
}
*/

.request-popup .leaflet-popup-content-wrapper {
  border-radius: 6px;
  background-color: white;
  color:black;
  /*min-width: 300px;*/
  align-items: center;
  justify-content: center;
}

.mini-icon  {
  height: 40px !important;
}

.contenitoreBorderImages .leaflet-popup-content-wrapper{
  width: 720px;
}

.contenitoreBorderImages{
  width: 720px;
}

.contenitoreBorderImagesResponsive .leaflet-popup-content-wrapper{
  width: 330px;
}

.contenitoreBorderImagesResponsive{
  width: 330px;
}

.initialStateContenitoreResponsive .leaflet-popup-content-wrapper{
  width: 150px;
}

.initialStateContenitoreResponsive{
  width: 150px;
}

.request-popup.show {
  display: block;
}


#plugins{
  z-index: 100000000000 !important;
}

#plugin-menu{
  z-index: 100000000000000 !important;
}

.plugin-content{
  z-index: 100000000000000 !important;
}

.uiyellow{
  z-index: 100000000000 !important;
}

#embed-zoom{
  z-index: 100000000000 !important;
  /*border: 1px solid black !important;*/
  margin-right: -5px !important;
}

#embed-zoom-div{
  z-index: 100000000000 !important;
  /*border: 1px solid black !important;*/
  margin-right: -5px !important;
  position: absolute !important;
  left: 3px !important;
  top: 80px !important;
  width: 240px !important;
}

.ControlPanelWindy{
  top: 80px;
  z-index: 100000000000000000 !important;
  display: none !important;
  position: absolute !important;
}

.scrollable-container {
  width: 220px; /* Sostituisci con l'altezza massima desiderata */
  overflow-y: auto;
  max-height: 200px;
  padding-top: 215px;
}

.scrollable-container::-webkit-scrollbar {
  width: 12px;
  }

.scrollable-container::-webkit-scrollbar-track {
    background-color: #ccc;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.scrollable-container::-webkit-scrollbar-thumb {
    background-color: rgba(105, 105, 105, 0.7);
    border-radius: 10px;
}

.zoom-plus{
  z-index: 100000000000 !important;
  margin-left: 70px !important;
  /*margin-left: 60px !important;*/
}

.zoom-minus{
  z-index: 100000000000 !important;
  margin-left: 70px !important;
  /*margin-left: 60px !important;*/
}

#mobile-ovr-select{
  z-index: 100000000000 !important;
}

.progress-line{
  z-index: 100000000000 !important;
}
/*
#logo{
  z-index: 100000000000 !important;
}
*/
.timecode{
  z-index: 100000000000 !important;
}


div#legend-mobile{
  z-index: 100000000000 !important;
}

#progress-bar{
  z-index: 100000000000 !important;
  margin-bottom: 10px !important;
}

#playpause{
  display: block !important;
}

.data-progress-line{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  height: 24px;
  /*background-color: linear-gradient(to right, #f2faff, #40a6c5);*/
}

#map-container{
  z-index: 100000000000 !important;
}

#mappaGoogle3D{
  width: 100vw;
  height: 100vh;
  display: none;
}

#mappa3D{
  width: 100vw;
  height: 100vh;
}

.vAygCK-api-load-alpha-banner{
  display: none !important;
}

/* Stili per il wrapper dello spinner */
.spinner-wrapper {
  position: absolute; /* Posiziona il wrapper all'interno del contenitore */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centra il wrapper */
  width: 100px; /* Dimensione del wrapper */
  height: 100px; /* Dimensione del wrapper */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0; /* Inizia con opacità 0 */
  visibility: hidden; /* Inizia nascosto */
  transition: opacity 0.7s ease, visibility 0s linear 0s; /* Transizione per opacità e visibilità */
}

/* Stili per la rotella di caricamento */
.loading-spinner {
  width: 100px; /* Dimensione dello spinner */
  height: 100px; /* Dimensione dello spinner */
  border: 5px solid #f3f3f3; /* Colore di sfondo del cerchio */
  border-radius: 50%; /* Crea un cerchio */
  border-top: 5px solid black; /* Colore della parte superiore del cerchio */
  animation: spin 1s linear infinite; /* Animazione di rotazione infinita */
}

/* Animazione di rotazione */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Stili per il testo all'interno dello spinner */
.spinner-text {
  position: absolute;
  font-size: 20px;
  color: black;
  z-index: 1; /* Assicura che il testo sia sopra lo spinner */
}



.drawing-mode .leaflet-container {
  cursor: crosshair; /* Cambia il cursore in modalità di disegno */
}

.visible-feature-group {
  opacity: 1 !important;
  pointer-events: auto !important;
}

.hidden-feature-group {
  opacity: 0 !important;
  pointer-events: none !important;
}

.legendaStep{
  display: flex;
  flex-direction: column;
  color: white;
}

.object-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  color: white;
}

.object-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;
}

.object-name {
  flex-grow: 1;
}

.remove-btn {
  background: white;
  color: red;
  border: none;
  cursor: pointer;
  padding: 3px 7px;
  border-radius: 3px;
}

/* ✅ Pulsante "+" */
.btn.btn-icon {
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-btn {
  margin-top: 10px;
  padding: 5px 10px;
  border: none;
  background: gray;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.close-btn:hover {
  background: darkgray;
} 

.ControlPanelProgetti{
  display: none !important;
}

.disabled-overlay {
  pointer-events: none;
  opacity: 0.5;
}
